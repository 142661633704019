import React from 'react'

class Form extends React.Component {
  render() {
    return (
      <form method="post" action="#">
        <label>
          Nombre
          <input type="text" className="form-input" name="name" id="name" />
        </label>
        <label>
          Asunto
          <input type="text" className="form-input" name="subject" id="subject" />
        </label>
        <label>
          Email
          <input type="email" className="form-input" name="email" id="email" />
        </label>
        <label>
          Mensaje
          <textarea name="message" className="form-input" id="message" rows="4" />
        </label>
        <button type="submit">Enviar</button>
      </form>
    )
  }
}

export default Form