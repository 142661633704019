import React from 'react'

class Header extends React.Component {
    render() {
        return (
            <section id="header">
                <div className="inner">
                    <h1  className="align-center">Antillean Software</h1>
                    {/* <p>Hacemos que la misión</p> <br /> 
                    <p>de nuestros clientes sea posible</p> */}
                </div>
            </section>
        )
    }
}

export default Header
