import React from "react";
import Helmet from "react-helmet";
import Form from "../components/Form";

import Layout from "../components/layout";
import Contact from "../components/Contact";

class Homepage extends React.Component {
  render() {
    const siteTitle = "Antillean Software";

    return (
      <Layout>
        <Helmet title={siteTitle} />

        <section id="one" className="main style1">
          <div className="grid-wrapper"></div>
        </section>

        <section id="two" className="main">
          <div className="grid-wapper">
            <div className="col-12">
              <p className="slogan align-center">
                Hacemos que la misión de nuestros clientes
              </p>
              <p className="slogan align-center">...</p>
              <p className="slogan align-center">Sea posible</p>
            </div>
            <div className="col-3"></div>
            <div className="col-6">
              <div className="main-description">
                <p className="align-center">
                  Antillean Software es una empresa que tiene la visión de
                  revolucionar la industria del software en Latinoamérica y el
                  Mundo. Nuestro equipo de profesionales tienen el objetivo de
                  proporcionar una amplia gama de ideas para satisfacer las
                  complejas necesidades existentes en el mercado con las últimas
                  tecnologías.
                </p>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </section>

        <section id="three" className="main style1 special">
          <div className="grid-wrapper">
            <div className="col-12">
              <header className="major">
                <h2>Nuestros Servicios</h2>
              </header>
            </div>

            <div className="col-4">
              <div className="service-box">
                <i className="icon fas fa-desktop"></i>
                <p>Desarrollo web</p>
              </div>
            </div>
            <div className="col-4">
              <div className="service-box service-center">
                <i className="icon fas fa-mobile"></i>
                <p>Desarrollo de aplicaciones móviles</p>
              </div>
            </div>
            <div className="col-4">
              <div className="service-box">
                <i className="icon fas fa-eye"></i>
                <p>UI UX Design</p>
              </div>
            </div>

            <div className="col-12 service-box-divider"></div>

            <div className="col-4">
              <div className="service-box">
                <i className="icon fas fa-medkit"></i>
                <p>Consultoría de Tecnología</p>
              </div>
            </div>
            <div className="col-4">
              <div className="service-box service-center">
                <i className="icon fas fa-globe"></i>
                <p>Software Outsourcing</p>
              </div>
            </div>
            <div className="col-4">
              <div className="service-box">
                <i className="icon fas fa-flask"></i>
                <p> Software Testing and Quality Assurance</p>
              </div>
            </div>

            <div className="col-12 service-box-divider"></div>
          </div>
        </section>

        <section id="whyus-section" className="main special">
          <div className="grid-wrapper">
            <header className="col-12">
              <h2>Porque Nosotros?</h2>
            </header>
            <div className="col-6">
              <ul className="fa-ul">
                <li>
                  <span className="fa-li">
                    <i className="fa fa-users"></i>
                  </span>
                  <p className="align-left">
                    Tenemos prácticas de contratación y recursos humanos
                    rigurosas y avanzadas, ofrecemos algunas de las condiciones
                    de empleo más atractivas y mostramos con orgullo algunas de
                    las tasas de retención más altas.
                  </p>
                </li>
                <li>
                  <span className="fa-li">
                    <i className="fa fa-line-chart"></i>
                  </span>
                  <p className="align-left">
                    Con la metodología ágil realizamos un proceso que permite un
                    desarrollo continuo e incremental para obtener resultados
                    óptimos.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-6 web-image"></div>
            <div className="col-12 tech-section">
            <header className="major tech-description">
              <p>Utilizamos las últimas tecnologías para el desarrollo de productos listos para satisfacer sus necesidades.</p>
            </header>
            </div>
          </div>
          <div className="grid-wrapper"></div>
        </section>

        <section id="four" className="main special">
          <div className="grid-wrapper">
            <header className="col-12 major">
              <h2>Proyectos</h2>
            </header>
            <div className="col-6 project-logo"></div>
            <div className="col-6">
              <p className="align-left">
                Es una aplicación enfocada en la emisión y manejo de recetas
                médicas en formato digital aprovechando el avance y la
                disponibilidad de la tecnología permitiendo un manejo de forma
                electrónica entre doctores y pacientes. Con RECDIGMEDICA se
                optimiza y se agiliza el despacho de medicamentos recetados en
                las farmacias asociadas y se eliminara el problema de las faltas
                ortográficas y la dificultad de lectura e interpretación de la
                receta, al mismo tiempo hace una contribución al medio ambiente
                reduciendo el uso del papel.
              </p>
            </div>
          </div>
        </section>

        <section id="five" className="main special">
          <Contact />
        </section>
        <section id="six" className="main special">
          <div className="grid-wrapper">
            <header className="col-12 major">
              <h2>Cuando Empezamos?</h2>
            </header>
            <div className="col-3"></div>
            <div className="col-6">
              <Form></Form>
            </div>
            <div className="col-3"></div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Homepage;
