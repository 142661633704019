import React from 'react';

class Contact extends React.Component {
    constructor(){
        super()
        this.state = {
            contactList: [
              'Jeffrey Sánchez CEO',
                'Adrian Troncoso COO',
                'Jose Angel Hidalgo CTO',
                'Jean Marcos Feliz CDO'
            ]
        };

        this.getContacts = this.getContacts.bind(this);
    }

    getContacts(){
        const contacts = this.state.contactList;
        return contacts.map( (c, index) => 
            <div key={index} className="col-3">
              <div className={`contact-photo team-${index}`}></div>
                <p className="team-title">{c}</p>
            </div>
        );
    }

    render(){
        return (
        <section>
          <div className="grid-wrapper" />
          </section>
        );
    }
}

export default Contact
